@import "./base/fa_height";

/* Main wrapper */
.zeitwert {
	min-height: 100vh;
	background-color: #eee;
}

/* Page */
.fa-page {
	position: absolute;
	top: 90px;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;

	&.only-breadcrumbs {
		top: 40px;
	}
}

/* Vertical line */
.fa-vertical-line {
	width: 2px;
	background-color: lightgrey;
	height: 80%;
	margin-left: auto;
	margin-right: auto;
	position: relative;
	top: 45%;
	transform: translateY(-50%);
}

@for $line from 1 through 10 {
	.fa-line-clamp-#{$line} {
		display: -webkit-box;
		-webkit-line-clamp: $line;
		-webkit-box-orient: vertical;
		overflow: hidden;
	}
}

.fa-full-width {
	width: 100%;
}

.fa-overflow {
	overflow: auto;
}

.fa-border-0 {
	border: 0;
}

.fa-border-top-0 {
	border-top: 0;
}

/* Split list view */
.fa-doc-item {
	border-radius: 0.25rem;
	border: 1px solid #dddbda;
	background-color: white;
}

.fa-add-button {
	background-color: transparent;
	text-align: left;
	vertical-align: middle;
	width: 100%;
	border-radius: 0.25rem;
	border: 0;
	padding: 0.25rem;
	padding-left: 1rem;

	&:hover {
		background-color: rgb(230, 230, 230);
	}
}

/* Item form filter */
.fa-subtitle {
	font-size: 1rem;
	font-weight: 700;
	line-height: 1.25;
	display: block;
}
