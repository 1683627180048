/* Height utilities */
.fa-col-fixed-height {
	display: flex;
	flex-grow: 1;
	min-height: 100%;

	> * {
		width: 100%;
	}
}

.fa-col-fixed-height {
	height: 100%;
	// This is to remove the outer scroll that is generated even if no content is shown (margins issue).
	overflow-y: hidden;
}

.fa-full-dims {
	width: 100%;
	height: 100%;
}

.fa-height-100 {
	height: 100%;
}

.fa-height-75 {
	height: 75%;
}

.fa-height-50 {
	height: 50%;
}

.fa-height-25 {
	height: 25%;
}

.fa-height_8-of-12 {
	height: 66.6666%;
}

.fa-height_4-of-12 {
	height: 33.3333%;
}

.fa-height-90-100 {
	min-height: 90%;
	max-height: 90%;
}

.fa-fixed-height {
	height: 100%;

	@for $line from 1 through 10 {
		$selector: "";
		@for $i from 1 through $line {
			$selector: $selector + "> *:not(.fa-fixed-height-stop)";
		}

		#{$selector} {
			height: 100%;
		}

		// This is included.
		.fa-fixed-height-stop {
			height: 100%;
		}
	}
}
