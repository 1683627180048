// Temperature indicator
$threshold: 25;
$amount: 1.1%;

.running-processes {
	color: black;

	@for $index from 0 through $threshold * 2 {
		.temperature-#{$index} {
			@if $index >= $threshold {
				$percentage: max(min($threshold - ($index - $threshold), 100), 0);
				background-color: lighten(#f88962, $percentage * $amount);
			} @else {
				$percentage: max(min($threshold - ($threshold - $index), 100), 0);
				background-color: lighten(#3c97dd, $percentage * $amount);
			}
		}
	}
}

.my-day-card-body-inner {
	height: calc(100% - 62px) !important;
}
