$markerWidth: 49px;
$markerHeight: 64px;

$originX: $markerWidth * .5;
$originY: $markerHeight;

.simpleMarker {
	background-image: url('./MapIcon.svg');
	position: absolute;
	cursor: pointer;
	width: $markerWidth;
	height: $markerHeight;
	top: -$originY;
	left: -$originX;
	transform-origin: $originX $originY;
	margin: 0;
	padding: 0;
}