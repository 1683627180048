
.slds-form-element-left, .slds-form-element-center, .slds-form-element-right {
	display: flex;
}

.slds-form-element-left {
	justify-content: left;
}

.slds-form-element-center {
	justify-content: center;
}

.slds-form-element-right {
	justify-content: right;
}
