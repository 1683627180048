.inbox-area {
	.slds-border_bottom:last-child {
		border-bottom: 0;
	}

	.conversations {
		// Full - header.
		height: calc(100% - 50px);
		overflow-y: auto;

		.conversation {
			min-height: 6rem;

			&.selected {
				--sds-c-card-color-background: lighten(#3c97dd, 35%);
				background-color: lighten(#3c97dd, 35%);
			}
		}

		.conversation-icon {
			position: absolute;
			bottom: 1rem;
			right: 1rem;
		}
	}

	.scrollable-chat {
		height: calc(100% - 57px);
	}
}

.scrollable-chat {
	position: relative;
	height: 100%;

	.scrollable-chat-inner {
		position: relative;
		// Height - chat answer
		height: calc(100% - 200px);
		overflow-y: auto;
	}

	.go-bottom {
		position: absolute;
		bottom: calc(137px + 1rem + 2rem);
		left: 50%;
		transform: translateX(-50%);
		cursor: pointer;
	}

	.chat-answer {
		position: absolute;
		bottom: 1rem;
		left: 1rem;
		right: 1rem;
	}
}

.chat-answer {
	textarea {
		resize: none;
	}

	&__reply {
		line-height: 2.5;
	}
}

.chat-spinner-background {
	position: absolute;
	width: 44px;
	height: 44px;
	background: white;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	border-radius: 100%;
	border: 3px solid #b0adab;
}

.chat-spinner {
	position: relative;
	top: 2rem;
	animation-name: fadeIn;
	animation-duration: 200ms;
}
