.fa-global-modal {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	z-index: 100;
	background-color: #f3f2f2;
	animation: 0.3s ease-out 0s 1 slideInFromLeft;
}

.fa-global-modal.slide-out {
	animation: 0.3s ease-in 0s 1 slideOutToRight;
	transform: translateX(100%);
}

@keyframes slideInFromLeft {
	0% {
		transform: translateX(-100%);
	}
	100% {
		transform: translateX(0);
	}
}

@keyframes slideOutToRight {
	0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(100%);
	}
}
