// Override variables.
:root {
	--sds-c-tabs-panel-spacing-block-start: 0;
	--sds-c-tabs-panel-spacing-block: 0;
}

/* Page Header */
.slds-page-header__name {
	vertical-align: top;
}

/* Card with fa-height-100 and header + footer*/
.slds-card.fa-height-100>.slds-card__header+.slds-card__body.slds-card__body_with_header_footer {
	height: calc(100% - 113px);
}

/* Favorites */
.slds-global-actions__favorites-action.slds-is-disabled,
.slds-global-actions__favorites-action:disabled {
	border-color: #919191;
}

/* Icon of Stateful Button */
.slds-button__icon--stateful,
.slds-button__icon_stateful {
	margin-top: 0.65em;
}

/* Sales Path */
.slds-path__track.slds-has-overflow .slds-path__scroller_inner {
	display: block;
}

/* Path */
.slds-path__nav .slds-is-lost .slds-path__link,
.slds-path__nav .slds-is-lost:hover .slds-path__link {
	color: white;
}

.slds-path__nav .slds-is-lost:hover:before,
.slds-path__nav .slds-is-lost:hover:after {
	background: darken(#c23934, 15%);
}

/* Avatar */
.slds-avatar__initials {
	text-transform: uppercase;
}

/* Split View */
.slds-split-view {
	.slds-split-view__header {
		background-color: #e3e3e9;
	}

	>.slds-page-header {
		border-radius: 0;
	}
}

.slds-split-view_container {
	max-width: 24rem !important;
}

.slds-split-view__list-item-action[aria-selected="true"]:focus {
	-webkit-box-shadow: inset 4px 0 0 #3f3f4e, inset 0 0 0 1px #3f3f4e;
	box-shadow: inset 4px 0 0 #3f3f4e, inset 0 0 0 1px #3f3f4e;
}

.slds-split-view__list-item-action[aria-selected="true"] {
	-webkit-box-shadow: inset 4px 0 0 #3f3f4e;
	box-shadow: inset 4px 0 0 #89898c;
}

.slds-split-view__list-item-action:focus {
	-webkit-box-shadow: inset 0 0 0 1px #89898c;
	box-shadow: inset 0 0 0 1px #89898c;
}

/* Tabs */
.slds-tabs_default {
	>.slds-tabs_default__content {
		overflow-x: hidden;
	}

	.slds-tabs_default__item.slds-disabled {
		opacity: 0.5;
	}

	&.full-height {
		height: 100%;

		>.slds-tabs_default__content {
			height: calc(100% - 40px);
			overflow-y: auto;
		}
	}
}

/* Combobox */
.slds-combobox__input[disabled]+.slds-button {
	display: none;
}

.slds-has-inline-listbox .slds-combobox__input {
	padding-top: 0.0625rem;
	padding-bottom: 0.0625rem;
}

/* Card - full height */
/* Note: this breaks home as they have title, so they are actually two cases */
.slds-card.fa-height-100 {
	>.slds-card__body {
		overflow: auto;
		height: 100%;
	}

	>.slds-card__header+.slds-card__body {
		height: calc(100% - 48px);
	}

	&.with-footer {
		>.slds-card__header+.slds-card__body {
			height: calc(100% - 88px);
		}

		.slds-card__footer {
			margin-top: 0;
		}
	}
}

.slds-card.original {
	border-width: var(--sds-c-card-sizing-border, 1px);
	border-style: solid;
	border-color: var(--sds-c-card-color-border, #dddbda);
	-webkit-box-shadow: var(--sds-c-card-shadow, 0 2px 2px 0 rgba(0, 0, 0, 0.1));
	box-shadow: var(--sds-c-card-shadow, 0 2px 2px 0 rgba(0, 0, 0, 0.1));
}

/* Icons */
.slds-icon-custom-note-private {
	background-color: var(--sds-c-icon-color-background, #e6d478);
}

/* Modal */
// .slds-modal__content {
// 	min-height: 20rem;
// }

.slds-slider {
	position: relative;

	.slds-slider__value {
		display: none;
	}

	input[type="range"] {
		&::before {
			content: "";
			position: absolute;
			z-index: 1;
			left: var(--right, "0%");
			width: 0.5rem;
			height: 0.5rem;
			top: -0.2rem;
			background-color: transparent;
			border-radius: 100%;
		}

		&::after {
			content: attr(data-after);
			position: absolute;
			z-index: 2;
			left: var(--left, "0%");
			top: 1rem;
			width: 3rem;
			transform: translateX(-50%);
			text-align: center;
		}
	}
}