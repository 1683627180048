.main-contact-bold {
	font-weight: bold;
}

.fa-account-goal-priority {
	position: absolute;
	top: 0.5rem;
	right: 1rem;
}

.fa-account-goal-chart-wrapper {
	.percentage {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 1;
		font-weight: bold;
		font-size: 1.1rem;
	}
}
