.page-docked {
	position: fixed;
	left: 0;
	right: 0;
	z-index: 20;
}

.page-dynamic-height {
	position: fixed;
	left: 12px;
	/* TODO */
	/* right: 12px; */
	right: 30px;
	z-index: 20;
}
