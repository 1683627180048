.fa-holding-block_title {
	background-color: #f3f2f2;
}

.fa-holding-icon-addition {
	background-color: #b781d3;
}

.fa-holding-icon-modify {
	background-color: #ff5d2d;
}

.fa-holding-icon-account,
.slds-icon-custom-custom45 {
	background-color: #00afa0;
}
