@media (min-width:80em) {

	[class*=slds-x-large-size--],
	[class*=slds-x-large-size_] {
		-webkit-box-flex: 0;
		-ms-flex: none;
		flex: none
	}

	.slds-x-large-size--1-of-1,
	.slds-x-large-size_1-of-1 {
		width: 100%
	}

	.slds-x-large-size--1-of-2,
	.slds-x-large-size_1-of-2 {
		width: 50%
	}

	.slds-x-large-size--2-of-2,
	.slds-x-large-size_2-of-2 {
		width: 100%
	}

	.slds-x-large-size--1-of-3,
	.slds-x-large-size_1-of-3 {
		width: 33.3333333333%
	}

	.slds-x-large-size--2-of-3,
	.slds-x-large-size_2-of-3 {
		width: 66.6666666667%
	}

	.slds-x-large-size--3-of-3,
	.slds-x-large-size_3-of-3 {
		width: 100%
	}

	.slds-x-large-size--1-of-4,
	.slds-x-large-size_1-of-4 {
		width: 25%
	}

	.slds-x-large-size--2-of-4,
	.slds-x-large-size_2-of-4 {
		width: 50%
	}

	.slds-x-large-size--3-of-4,
	.slds-x-large-size_3-of-4 {
		width: 75%
	}

	.slds-x-large-size--4-of-4,
	.slds-x-large-size_4-of-4 {
		width: 100%
	}

	.slds-x-large-size--1-of-5,
	.slds-x-large-size_1-of-5 {
		width: 20%
	}

	.slds-x-large-size--2-of-5,
	.slds-x-large-size_2-of-5 {
		width: 40%
	}

	.slds-x-large-size--3-of-5,
	.slds-x-large-size_3-of-5 {
		width: 60%
	}

	.slds-x-large-size--4-of-5,
	.slds-x-large-size_4-of-5 {
		width: 80%
	}

	.slds-x-large-size--5-of-5,
	.slds-x-large-size_5-of-5 {
		width: 100%
	}

	.slds-x-large-size--1-of-6,
	.slds-x-large-size_1-of-6 {
		width: 16.6666666667%
	}

	.slds-x-large-size--2-of-6,
	.slds-x-large-size_2-of-6 {
		width: 33.3333333333%
	}

	.slds-x-large-size--3-of-6,
	.slds-x-large-size_3-of-6 {
		width: 50%
	}

	.slds-x-large-size--4-of-6,
	.slds-x-large-size_4-of-6 {
		width: 66.6666666667%
	}

	.slds-x-large-size--5-of-6,
	.slds-x-large-size_5-of-6 {
		width: 83.3333333333%
	}

	.slds-x-large-size--6-of-6,
	.slds-x-large-size_6-of-6 {
		width: 100%
	}

	.slds-x-large-size--1-of-7,
	.slds-x-large-size_1-of-7 {
		width: 14.2857142857%
	}

	.slds-x-large-size--2-of-7,
	.slds-x-large-size_2-of-7 {
		width: 28.5714285714%
	}

	.slds-x-large-size--3-of-7,
	.slds-x-large-size_3-of-7 {
		width: 42.8571428571%
	}

	.slds-x-large-size--4-of-7,
	.slds-x-large-size_4-of-7 {
		width: 57.1428571429%
	}

	.slds-x-large-size--5-of-7,
	.slds-x-large-size_5-of-7 {
		width: 71.4285714286%
	}

	.slds-x-large-size--6-of-7,
	.slds-x-large-size_6-of-7 {
		width: 85.7142857143%
	}

	.slds-x-large-size--7-of-7,
	.slds-x-large-size_7-of-7 {
		width: 100%
	}

	.slds-x-large-size--1-of-8,
	.slds-x-large-size_1-of-8 {
		width: 12.5%
	}

	.slds-x-large-size--2-of-8,
	.slds-x-large-size_2-of-8 {
		width: 25%
	}

	.slds-x-large-size--3-of-8,
	.slds-x-large-size_3-of-8 {
		width: 37.5%
	}

	.slds-x-large-size--4-of-8,
	.slds-x-large-size_4-of-8 {
		width: 50%
	}

	.slds-x-large-size--5-of-8,
	.slds-x-large-size_5-of-8 {
		width: 62.5%
	}

	.slds-x-large-size--6-of-8,
	.slds-x-large-size_6-of-8 {
		width: 75%
	}

	.slds-x-large-size--7-of-8,
	.slds-x-large-size_7-of-8 {
		width: 87.5%
	}

	.slds-x-large-size--8-of-8,
	.slds-x-large-size_8-of-8 {
		width: 100%
	}

	.slds-x-large-size--1-of-12,
	.slds-x-large-size_1-of-12 {
		width: 8.3333333333%
	}

	.slds-x-large-size--2-of-12,
	.slds-x-large-size_2-of-12 {
		width: 16.6666666667%
	}

	.slds-x-large-size--3-of-12,
	.slds-x-large-size_3-of-12 {
		width: 25%
	}

	.slds-x-large-size--4-of-12,
	.slds-x-large-size_4-of-12 {
		width: 33.3333333333%
	}

	.slds-x-large-size--5-of-12,
	.slds-x-large-size_5-of-12 {
		width: 41.6666666667%
	}

	.slds-x-large-size--6-of-12,
	.slds-x-large-size_6-of-12 {
		width: 50%
	}

	.slds-x-large-size--7-of-12,
	.slds-x-large-size_7-of-12 {
		width: 58.3333333333%
	}

	.slds-x-large-size--8-of-12,
	.slds-x-large-size_8-of-12 {
		width: 66.6666666667%
	}

	.slds-x-large-size--9-of-12,
	.slds-x-large-size_9-of-12 {
		width: 75%
	}

	.slds-x-large-size--10-of-12,
	.slds-x-large-size_10-of-12 {
		width: 83.3333333333%
	}

	.slds-x-large-size--11-of-12,
	.slds-x-large-size_11-of-12 {
		width: 91.6666666667%
	}

	.slds-x-large-size--12-of-12,
	.slds-x-large-size_12-of-12 {
		width: 100%
	}

	.slds-x-large-order--1,
	.slds-x-large-order_1 {
		-webkit-box-ordinal-group: 2;
		-ms-flex-order: 1;
		order: 1
	}

	.slds-x-large-order--2,
	.slds-x-large-order_2 {
		-webkit-box-ordinal-group: 3;
		-ms-flex-order: 2;
		order: 2
	}

	.slds-x-large-order--3,
	.slds-x-large-order_3 {
		-webkit-box-ordinal-group: 4;
		-ms-flex-order: 3;
		order: 3
	}

	.slds-x-large-order--4,
	.slds-x-large-order_4 {
		-webkit-box-ordinal-group: 5;
		-ms-flex-order: 4;
		order: 4
	}

	.slds-x-large-order--5,
	.slds-x-large-order_5 {
		-webkit-box-ordinal-group: 6;
		-ms-flex-order: 5;
		order: 5
	}

	.slds-x-large-order--6,
	.slds-x-large-order_6 {
		-webkit-box-ordinal-group: 7;
		-ms-flex-order: 6;
		order: 6
	}

	.slds-x-large-order--7,
	.slds-x-large-order_7 {
		-webkit-box-ordinal-group: 8;
		-ms-flex-order: 7;
		order: 7
	}

	.slds-x-large-order--8,
	.slds-x-large-order_8 {
		-webkit-box-ordinal-group: 9;
		-ms-flex-order: 8;
		order: 8
	}

	.slds-x-large-order--9,
	.slds-x-large-order_9 {
		-webkit-box-ordinal-group: 10;
		-ms-flex-order: 9;
		order: 9
	}

	.slds-x-large-order--10,
	.slds-x-large-order_10 {
		-webkit-box-ordinal-group: 11;
		-ms-flex-order: 10;
		order: 10
	}

	.slds-x-large-order--11,
	.slds-x-large-order_11 {
		-webkit-box-ordinal-group: 12;
		-ms-flex-order: 11;
		order: 11
	}

	.slds-x-large-order--12,
	.slds-x-large-order_12 {
		-webkit-box-ordinal-group: 13;
		-ms-flex-order: 12;
		order: 12
	}
}